* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #ff4d4f !important;
}
.ant-steps-item-process .ant-steps-item-icon {
    border-color: #ff0000 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #ff4d4f !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #ff0000 !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #ff0000 !important;
}
/* Message Styles */
.ant-steps-item-icon .ant-steps-icon {
    top: -2px !important;
}
.ant-switch-checked {
    background-color: #a3af60 !important;
}
.anticon {
    vertical-align: 0.09em !important;
}
.ant-input-number {
    width: 100% !important;
    padding: 10px 20px !important;
}
.bg_comp {
    background-image: url('/assets/images/comp1.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.message {
    margin-bottom: 16px;
    width: 100%;
}

.message__name {
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
}

.message__meta {
    color: #777;
    font-size: 14px;
}

.message a {
    color: #0070cc;
}
.chat__sidebar {
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 80%;
    color: white;
    /* background: #999; */
    overflow-y: auto;
}
.DIV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1.5px solid #eee;
}
.DIV:hover {
    background: #e4e4e485;
}
.heading-2 {
    padding: 15px;
    /* height: 10vh; */
    font-size: 20px;
    letter-spacing: 2px;
    color: #222;
    text-transform: capitalize;
    background-color: #e4e4e485;
    margin-top: 0;
}
.main {
    display: flex;
    height: calc(100vh - 100px);
}

.video {
    flex: 0 0 70%;
    display: flex;
    background-color: aliceblue;
}

.sidebar {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #eee;
}
.guest {
    width: 100%;
    height: 47%;
}
.speaker {
    height: 47%;
    margin-top: 10px;
}

#style-5::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
    background-color: #0ae;

    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        color-stop(0.5, rgba(255, 255, 255, 0.2)),
        color-stop(0.5, transparent),
        to(transparent)
    );
}
.ck-editor__editable_inline {
    min-height: 500px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #a3af60 !important;
}
.ant-input {
    padding: 10px 20px !important;
}
.ant-form-item-control-input .ant-select-selector {
    height: 52px !important;
    padding: 10px 20px !important;
}

.ant-table table {
    border-radius: 8px !important;
    overflow: hidden !important;
    border: 1px solid #c7c7c7 !important;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0 !important;
}
.formulaTag {
    background-color: #e8f8f8 !important;
    font-size: 16px !important;
    padding: 15px !important;
    border-radius: 15px !important;
}
.ant-table-pagination > * {
    flex: none;
    font-weight: 500 !important;
    border-radius: 10px !important;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #313d3b !important;
    color: #fff !important;
    /* border-color: #1890ff; */
    border: none !important;
    border-radius: 10px !important;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector,  */
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link
{
    border-radius: 10px !important;
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
    border-color: #313d3b !important;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover, */
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link:hover{
    border-color: #313d3b !important;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #313d3b !important;
    color: #313d3b !important;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #313d3b !important;
}
.content-page {
    padding-top: 0px !important;
}


.faq-button{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
.faq-button:not(.collapsed) {
    color: #313d3b;
    /* background-color: #f9faf2; */
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.faq-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
}
.faq-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23313D3B'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(
-180deg);
}